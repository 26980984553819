<template>
  <footer class="footer">
    <div class="container footer-text">
      <div class="columns is-multiline">
        <div class="column">
          <div class="columns is-multiline" :class="{ 'is-mobile': isMobile }">
            <!-- services -->
            <div
              class="column is-one-fifth is-one-fifth-desktop is-4-tablet is-6-mobile"
              :class="{ 'is-6': isMobile }"
            >
              <links :items="Object.values(items.services)" />
            </div>
            <!-- about company -->
            <div
              class="column is-one-fifth is-one-fifth-desktop is-4-tablet is-6-mobile"
              :class="{ 'is-6': isMobile }"
            >
              <links :items="Object.values(items.about)" />
            </div>
            <!-- terms and conditions -->
            <div
              class="column is-one-fifth is-one-fifth-desktop is-4-tablet is-6-mobile"
              :class="{ 'is-6': isMobile }"
            >
              <links :items="Object.values(items.legal)" />
            </div>
            <!-- contacts -->
            <div
              class="column is-one-fifth is-one-fifth-desktop is-4-tablet is-6-mobile"
              :class="{ 'is-6': isMobile }"
            >
              <contacts :items="Object.values(items.contacts)" />
            </div>
            <!-- socials -->
            <div
              class="column is-one-fifth is-one-fifth-desktop is-4-tablet is-6-mobile"
              :class="{ 'is-6': isMobile }"
            >
              <socials :items="Object.values(items.social)" />
            </div>
          </div>
        </div>
        <div
          class="column is-one-quarter is-one-quarter-desktop is-12-tablet is-12-mobile is-size-7"
        >
          <!-- logo -->
          <app-logo style="width: 164px" horizontal></app-logo>
          <!-- copyright -->
          <p>
            {{ $t('footer.company.copyrights', { start: '2018', end: currYear }) }}
          </p>
          <p>
            {{ $t('footer.company.address') }}
          </p>
          <p>
            {{ $t('footer.company.bulstat') }}
          </p>
          <p class="mb-5">
            {{ $t('footer.company.license') }}
          </p>
          <!-- <store-links /> -->
          <div class="store-links start mb-5">
            <a
              href="https://play.google.com/store/apps/details?id=com.blockchaintech.cryptodesk&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
              class="mr-5"
            >
              <b-image
                class="store-image"
                :src="require(`@/assets/images/googleplay.svg`)"
              />
            </a>
            <a
              href="https://apps.apple.com/bg/app/cryptodesk/id6445974552"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b-image
                class="store-image"
                :src="require(`@/assets/images/appstore.svg`)"
              />
            </a>
          </div>
          <!-- subscribe form -->
          <!-- <ValidationObserver v-slot="{ passes, valid }" tag="div">
            <form
              @submit.prevent
              class="is-flex is-justify-content-space-between is-align-items-center"
            >
              <ValidationProvider
                rules="required|email"
                :name="$t('fields.email')"
                mode="eager"
                v-slot="{ errors }"
              >
                <b-field :message="errors[0]">
                  <b-input
                    v-model="email"
                    type="text"
                    :placeholder="$t('fields.email')"
                  >
                  </b-input>
                  <p class="control">
                    <b-button
                      type="is-primary"
                      :label="$t('buttons.subscribe')"
                      :disabled="!valid"
                      style="height: 100%; border-radius: 0 5px 5px 0"
                      @click.prevent="passes(subscribe)"
                    />
                  </p>
                </b-field>
              </ValidationProvider>
            </form>
          </ValidationObserver> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { VUE_APP_SUPPORT_EMAIL, VUE_APP_SUPPORT_PHONE } from '@/utils/constants'
import VueBreakpointMixin from 'vue-breakpoint-mixin'
import AppLogo from '@/components/ui/AppLogo.vue'
import Links from '@/components/layout/AppFooter/Links.vue'
// import Documents from '@/components/layout/AppFooter/Documents.vue'
import Socials from '@/components/layout/AppFooter/Socials.vue'
import Contacts from '@/components/layout/AppFooter/Contacts.vue'

export default {
  name: 'AppFooter',
  components: {
    AppLogo,
    Links,
    // Documents,
    Socials,
    Contacts,
  },
  mixins: [VueBreakpointMixin],
  data() {
    return {
      email: null,
    }
  },
  computed: {
    currYear() {
      return new Date().getFullYear()
    },
    items() {
      return {
        services: {
          label: this.$t('footer.links.services.label'),
          btcPay: { to: 'https://btcpay.cryptodesk.bg', title: this.$t('footer.links.services.btcPay'), tag: 'a', target: '_blank' },
          eShop: { to: 'https://estore.cryptodesk.bg', title: this.$t('footer.links.services.eShop'), tag: 'a', target: '_blank' },
          ln: { to: 'https://ln.cryptodesk.bg', title: this.$t('footer.links.services.ln'), tag: 'a', target: '_blank' },
          btcBlok: { to: 'https://explorer.cryptodesk.bg', title: this.$t('footer.links.services.btcBlok'), tag: 'a', target: '_blank' },
        },
        about: {
          label: this.$t('footer.links.about.label'),
          aboutUs: { to: '/about', title: this.$t('footer.links.about.aboutUs'), tag: 'router-link' },
          blog: { to: 'https://blog.cryptodesk.bg/', title: this.$t('footer.links.about.blog'), tag: 'a', target: '_blank' },
          help: { to: 'https://help.cryptodesk.bg/ ', title: this.$t('footer.links.about.help'), tag: 'a', target: '_blank' },
        },
        legal: {
          label: this.$t('footer.links.legal.label'),
          terms: { to: '/terms', title: this.$t('footer.links.legal.terms'), tag: 'router-link' },
          gdpr: { to: '/privacy', title: this.$t('footer.links.legal.gdpr'), tag: 'router-link' },
          biometric: { to: '/biometrics', title: this.$t('footer.links.legal.biometric'), tag: 'router-link' },
          fees: { to: '/fees', title: this.$t('footer.links.legal.fees'), tag: 'router-link' },
          // aml: { title: this.$t('footer.links.legal.aml'), to: '' },
        },
        social: {
          label: this.$t('footer.links.social.label'),
          facebook: {
            title: this.$t('footer.links.social.facebook'),
            to: 'https://www.facebook.com/cryptodesk.bg',
            icon: 'social/footer/facebook.png',
          },
          twitter: {
            title: this.$t('footer.links.social.twitter'),
            to: 'https://twitter.com/cryptodeskbg',
            icon: 'social/footer/twitter.png',
          },
          instagram: {
            title: this.$t('footer.links.social.instagram'),
            to: 'https://www.instagram.com/cryptodesk.bg/?hl=bg',
            icon: 'social/footer/instagram.png',
          },
          reddit: {
            title: this.$t('footer.links.social.reddit'),
            to: 'https://www.reddit.com/user/CryptoDeskBG',
            icon: 'social/footer/reddit.png',
          },
          discord: {
            title: this.$t('footer.links.social.discord'),
            to: 'https://discord.com/invite/PtnjrNP7uN',
            icon: 'social/footer/discord.png',
          },
          tiktok: {
            title: this.$t('footer.links.social.tiktok'),
            to: 'https://www.tiktok.com/@cryptodesk',
            icon: 'social/footer/tiktok.png',
          },
        },
        contacts: {
          label: this.$t('footer.links.contacts.label'),
          email: {
            title: this.$t('footer.links.contacts.email'),
            email: VUE_APP_SUPPORT_EMAIL,
            icon: 'at',
          },
          phone: {
            title: this.$t('footer.links.contacts.phone'),
            phone: VUE_APP_SUPPORT_PHONE,
            icon: 'phone',
          },
          whatsapp: {
            title: this.$t('footer.links.contacts.whatsapp'),
            to: 'https://wa.me/359877750733',
            icon: 'social/footer/whatsapp.png',
          },
          telegram: {
            title: this.$t('footer.links.contacts.telegram'),
            to: 'https://t.me/cryptodesk_bg',
            icon: 'social/footer/telegram.png',
          },
          // signal: {
          //   title: this.$t('footer.links.contacts.signal'),
          //   to: '',
          //   icon: '',
          // },
          // viber: {
          //   title: this.$t('footer.links.contacts.viber'),
          //   to: '',
          //   icon: '',
          // },
        },
      }
    },
  },
  methods: {
    subscribe() {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Not implemented!',
        position: 'is-bottom',
        type: 'is-warning',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-text {
  color: $white;
}

.socials {
  margin-top: 0.5rem;
}

.store-links {
  display: flex;

  .store-image {
    width: 128px;
  }
}
.store-links.between {
  justify-content: space-between;
}
.store-links.start {
  justify-content: start;
}
.control ::v-deep input {
  border: 1px solid gray;
  border-radius: 5px 0 0 5px;
  box-shadow: none !important;
}
</style>
